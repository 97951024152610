import React from 'react';
import { skills } from '../../constants/data';

import SkillCard from './SkillCard';

const Skills = () => {
  return (
    <section className="section skills" id="skills">
      <div className="section__container skills__container">
        <h1 className="section__title">Skills</h1>
        <div className="skills__cards-container">
          {skills.map((skill, index) => (
            <SkillCard
              key={index}
              img={skill.img}
              title={skill.title}
              desc={skill.desc}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Skills;
