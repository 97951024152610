import React, { useState, useEffect } from 'react';
import { FaWrench } from 'react-icons/fa';
import { AiFillHome } from 'react-icons/ai';
import { MdWork } from 'react-icons/md';
import { IoMdContact } from 'react-icons/io';
import { SiAboutdotme } from 'react-icons/si';
import useResponsive from '../hooks/useResponsive';

const navLinks = [
  { name: 'Home', link: '#hero', icon: <AiFillHome /> },
  { name: 'About', link: '#about', icon: <SiAboutdotme /> },
  { name: 'Skills', link: '#skills', icon: <FaWrench /> },
  { name: 'Portfolio', link: '#work', icon: <MdWork /> },
  { name: 'Contact', link: '#footer', icon: <IoMdContact /> },
];

const Navbar = () => {
  const { isMobile } = useResponsive();
  const [show, setShow] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);

  const controlNavbar = () => {
    if (typeof window !== 'undefined') {
      setShow(
        window.scrollY > lastScrollY && window.scrollY > 100 ? false : true
      );
      setLastScrollY(window.scrollY);
    }
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', controlNavbar);

      return () => {
        window.removeEventListener('scroll', controlNavbar);
      };
    }
  }, [lastScrollY]);

  return (
    <div
      className={`navbar ${lastScrollY > 150 && 'navbar--shadow'} ${
        show ? 'navbar--show' : 'navbar--hide'
      }`}
    >
      <div className="container navbar__container">
        <div
          className="navbar__actions"
          animate={{ translateX: ['100%', '0%'] }}
          transition={{ duration: 0.35 }}
        >
          <ul className="navbar__links">
            {navLinks.map((link) => (
              <li className="navbar__link" key={link.name}>
                <a href={link.link}>
                  <i className="navbar__link-icon">{link.icon}</i>
                  {!isMobile && <span>{link.name}</span>}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
