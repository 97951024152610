import React from 'react';
import { Navbar } from './components';
import { Hero, About, Skills, Work, Contact, Footer } from './containers';

import './styles/main.scss';

const App = () => {
  return (
    <div className="app">
      <Navbar />
      <main className="app-sections">
        <Hero />
        <About />
        <Skills />
        <Work />
        <Contact />
        <Footer />
      </main>
    </div>
  );
};

export default App;
