import React from 'react';

const SkillCard = ({ img, title }) => {
  return (
    <div className="skill-card">
      <div className="skill-card__img">
        <img src={img} alt={title} />
      </div>
      <div className="skill-card__title">
        <p>{title}</p>
      </div>
    </div>
  );
};

export default SkillCard;
