import React from "react";
import { motion } from "framer-motion";
import { Button } from "../../components";
import resume from "../../assets/resume/akash_gupta-resume.pdf";
import { fadeIn, slideIn, staggerContainer } from "../../utils/motion";

const About = () => {
  return (
    <section className="section about" id="about">
      <motion.div
        variants={staggerContainer}
        initial="hidden"
        whileInView="show"
        viewport={{ once: true, amount: 0.25 }}
        className="section__container about__container"
      >
        <motion.h1
          variants={fadeIn("up", "tween", 0.2, 0.4)}
          className="section__title"
        >
          About Me
        </motion.h1>
        <motion.div
          variants={fadeIn("up", "tween", 0.3, 0.8)}
          className="section__text about__text"
        >
          <p>
            Hello there! My name is Akash Gupta, and I love to build things for
            the web.
          </p>
          <p>
            Discovering the art of designing captivating user interfaces and
            creating beautiful web experiences has been incredibly satisfying to
            me and I have been learning and building web applications ever
            since.
          </p>
          <p>
            Have an idea for a project? Let's talk about it. I am always open to
            a new challenge and I would love to use my skills to help your
            business grow. I am available for freelance work. Feel free to{" "}
            <span>
              <a href="#contact">contact me</a>
            </span>
            .
          </p>
          <p>Want to know more about me? check out my resume ↓</p>
        </motion.div>
        <motion.a
          variants={fadeIn("up", "tween", 0.3, 0.8)}
          className="download-btn"
          href={resume}
          download="akash_gupta-resume"
        >
          <Button
            btnClassName="hero__btn"
            buttonStyle="btn--solid"
            text="Download Resume"
            iconPosition="right"
          />
        </motion.a>
      </motion.div>
    </section>
  );
};

export default About;
