import React from 'react';

const ProjectCard = ({ img, title, desc, link, githubLink }) => {
  return (
    <div className="project-card">
      <div className="project-card__img">
        <img src={img} alt={title} />
      </div>
      <div className="project-card__info">
        <a
          href={link === '' ? githubLink : link}
          target="_blank"
          rel="noopener noreferrer"
        >
          <h3 className="project-card__title">{title}</h3>
        </a>
        <p className="project-card__desc">{desc}</p>
      </div>
    </div>
  );
};

export default ProjectCard;
