import { email, github, linkedin, instagram } from '../images';

const socials = [
  {
    name: 'Email',
    link: 'mailto:ag308669@gmial.com',
    icon: email,
    desc: 'Feel free to email me',
  },
  {
    name: 'Github',
    link: 'https://github.com/Akash4510',
    icon: github,
    desc: 'Where I upload my projects',
  },
  {
    name: 'Linkedin',
    link: 'https://www.linkedin.com/in/akash-gupta-12262a1b7/',
    icon: linkedin,
    desc: 'Where I share my experiences',
  },
  {
    name: 'Instagram',
    link: 'https://www.instagram.com/akash_gupta_4510/',
    icon: instagram,
    desc: 'Connect with me on Instagram',
  },
];

export default socials;
