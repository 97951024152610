import React, { useState, useRef } from 'react';
import { motion } from 'framer-motion';
import contactImg from '../../assets/img/contact-img.svg';
import emailjs from '@emailjs/browser';

const Contact = () => {
  const form = useRef();

  const [loading, setLoading] = useState(false);
  const [formValue, setFormValue] = useState({
    firstName: '',
    lastName: '',
    email: '',
    message: '',
  });

  const sendEmail = (e) => {
    e.preventDefault();
    setLoading(true);

    emailjs
      .sendForm(
        'service_okdr7bw',
        'template_da16r76',
        form.current,
        'de9A70-6svNhNvCI6'
      )
      .then(
        (result) => {
          console.log(result.text);
          alert('Message sent successfully!');
        },
        (error) => {
          console.log(error.text);
        }
      )
      .catch((err) => console.log(err))
      .finally(() => {
        setLoading(false);
        setFormValue({
          firstName: '',
          lastName: '',
          email: '',
          message: '',
        });
      });
  };

  return (
    <section className="section contact" id="contact">
      <div className="section__container contact__container">
        <h1 className="section__title">Contact Me</h1>
        <p className="section__text contact__text">
          I am currently looking for new opportunities. My inbox is always open
          whether you have a question or just want to say hi, I'll try my best
          to get back to you!
        </p>
        <div className="contact__box">
          <form ref={form} className="contact__form" onSubmit={sendEmail}>
            <div className="contact__form-group-wrapper">
              <div className="contact__form-group">
                <label htmlFor="firstName">First Name</label>
                <input
                  type="text"
                  id="firstName"
                  name="firstName"
                  placeholder="First Name"
                  value={formValue.firstName}
                  onChange={(e) =>
                    setFormValue({ ...formValue, firstName: e.target.value })
                  }
                  required
                />
              </div>
              <div className="contact__form-group">
                <label htmlFor="lastName">Last Name</label>
                <input
                  type="text"
                  id="lastName"
                  name="lastName"
                  placeholder="Last Name"
                  value={formValue.lastName}
                  onChange={(e) =>
                    setFormValue({ ...formValue, lastName: e.target.value })
                  }
                  required
                />
              </div>
            </div>

            <div className="contact__form-group">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                name="email"
                placeholder="Email"
                value={formValue.email}
                onChange={(e) =>
                  setFormValue({ ...formValue, email: e.target.value })
                }
                required
              />
            </div>
            <div className="contact__form-group">
              <label htmlFor="message">Message</label>
              <textarea
                id="message"
                name="message"
                placeholder="Enter your message here..."
                value={formValue.message}
                onChange={(e) =>
                  setFormValue({ ...formValue, message: e.target.value })
                }
                required
              />
            </div>
            <button type="submit" className="btn btn--solid" disabled={loading}>
              {loading ? 'Sending...' : 'Send Message'}
            </button>
          </form>

          <div className="contact__img">
            <motion.div
              animate={{ y: [30, 0, 30], rotate: [0, -4, 0] }}
              transition={{ duration: 6, repeat: 'Infinity' }}
            >
              <img src={contactImg} alt="contact" />
            </motion.div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
