import React from 'react';
import { motion } from 'framer-motion';
import { Typewriter } from 'react-simple-typewriter';
import { Button } from '../../components';
import heroImg from '../../assets/img/hero-img.png';
import resume from '../../assets/resume/akash_gupta-resume.pdf';
import { staggerContainer, fadeIn } from '../../utils/motion';

const Hero = () => {
  const desc = [
    ' Full Stack Web Developer',
    ' Creative UI-UX Designer',
    'n Enthusiastic Programmer',
  ];

  return (
    <section className="section hero" id="hero">
      <motion.div
        variants={staggerContainer}
        initial="hidden"
        whileInView="show"
        viewport={{ once: true, amount: 0.25 }}
        className="section__container hero__container"
      >
        <div className="hero__text">
          <motion.h3
            variants={fadeIn('down', 'tween', 0.5, 0.8)}
            className="hero__text-hello"
          >
            Hello <span className="hero__text-icon">👋</span>,
          </motion.h3>
          <motion.h1
            variants={fadeIn('down', 'tween', 0.6, 0.8)}
            className="hero__text-name"
          >
            I'm <span>Akash</span>
          </motion.h1>
          <motion.h2
            variants={fadeIn('up', 'tween', 0.7, 0.5)}
            viewport={{ once: true }}
            className="hero__text-desc"
          >
            A
            <span>
              <Typewriter
                words={desc}
                loop={0}
                cursor
                cursorStyle="|"
                typeSpeed={100}
                deleteSpeed={70}
                delaySpeed={2000}
              />
            </span>
          </motion.h2>
          <motion.a
            variants={fadeIn('up', 'tween', 0.8, 0.8)}
            className="download-btn"
            href={resume}
            download="akash_gupta-resume"
          >
            <Button
              btnClassName="hero__btn"
              buttonStyle="btn--solid"
              text="Download Resume"
              iconPosition="right"
            />
          </motion.a>
        </div>

        <motion.div
          variants={fadeIn('left', 'tween', 0.2, 1)}
          className="hero__img"
        >
          <motion.div
            animate={{ y: [30, 0, 30], rotate: [0, -4, 0] }}
            transition={{ duration: 4, repeat: 'Infinity' }}
          >
            <img src={heroImg} alt="profile-pic" />
          </motion.div>
        </motion.div>
      </motion.div>
    </section>
  );
};

export default Hero;
