import React from 'react';
import useResponsive from '../../hooks/useResponsive';
import { socials } from '../../constants/data';

const SocialBox = ({ name, link, icon, desc }) => {
  return (
    <a
      href={link}
      target="_blank"
      rel="noopener noreferrer"
      className="footer__social-box"
    >
      <img className="footer__social-icon" src={icon} alt={name} />
      <div className="footer__social-info">
        <h2 className="footer__social-title">{name}</h2>
        <p className="footer__social-desc">{desc}</p>
      </div>

      <span className="footer__social-arrow">→</span>
    </a>
  );
};

const Footer = () => {
  const { isMobile } = useResponsive();

  return (
    <section className="section footer" id="footer">
      <div className="section__container footer__container">
        <h1 className="section__title footer__title">Let's Connect</h1>
        <div className="footer__social">
          {socials.map((social, index) => (
            <SocialBox key={index} {...social} />
          ))}
        </div>
        <div className="footer__text">
          <p>
            Thank you for visiting my portfolio. {isMobile && <br />}
            Have a nice day!{' '}
            <span role="img" aria-label="heart">
              ❤️
            </span>
          </p>
        </div>
      </div>
    </section>
  );
};

export default Footer;
