import React from 'react';
import { motion } from 'framer-motion';
import ProjectCard from './ProjectCard';
import { projects } from '../../constants/data';
import contactImg from '../../assets/img/contact-img.svg';

const Work = () => {
  return (
    <section className="section work" id="work">
      <div className="section__container work__container">
        <h2 className="section__title work__title">My Works</h2>
        <div className="section__text work__text">
          <p>
            Here are a few projects I've worked on recently. Want to see more?
            Visit my{' '}
            <span>
              <a href="https://github.com/Akash4510">Github</a>
            </span>
            .
          </p>
        </div>
        <div className="work__projects">
          {projects.map((project, index) => (
            <ProjectCard key={index} {...project} />
          ))}
        </div>

        <div className="work__img">
          <motion.div
            animate={{ y: [30, 0, 30], rotate: [0, -4, 0] }}
            transition={{ duration: 6, repeat: 'Infinity' }}
          >
            <img src={contactImg} alt="work" />
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default Work;
