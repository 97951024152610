import {
  airbnbClone,
  aiCompanion,
  letsChat,
  passwordManager,
  propmtopia,
  metaversus,
  regressors,
} from '../images';

const projects = [
  {
    title: 'AI Companion',
    desc: 'AI Companion is a full-stack SaaS application that empowers users to create their very own AI companions and engage in meaningful conversations with them.',
    link: 'https://ai-companion-nu.vercel.app/',
    githubLink: 'https://github.com/Akash4510/ai-companion',
    img: aiCompanion,
  },
  {
    title: 'Airbnb Clone',
    desc: 'An Airbnb clone built with Next.js 13, TypeScript, Tailwind CSS, and MongoDB. It includes authentication, map integration, and more!',
    link: 'https://stay-scape.vercel.app/',
    githubLink: 'https://github.com/Akash4510/airbnb-clone',
    img: airbnbClone,
  },
  {
    title: "Let's Chat",
    desc: 'A modern chat application built using React, Redux, Node.js, Express.js, MongoDB, Socket.io',
    link: '',
    githubLink: 'https://github.com/Akash4510/lets-chat',
    img: letsChat,
  },
  {
    title: 'Password Manager',
    desc: 'An application to manage your passwords, safely and securely. Built using Python, Tkinter, MongoDB, Flask',
    link: '',
    githubLink: 'https://github.com/Akash4510/password-manager',
    img: passwordManager,
  },
  {
    title: 'Promptopia',
    desc: 'Promptopia is an AI prompting tool for modern world to discover, create and share creative prompts',
    link: 'https://promptopia-tan.vercel.app/',
    githubLink: 'https://github.com/Akash4510/promptopia',
    img: propmtopia,
  },
  {
    title: 'Metaversus',
    desc: 'Metaversus is the modern design web application for the metaverse where you can explore different virtual worlds',
    link: 'http://metaversus-iota.vercel.app/',
    githubLink: 'https://github.com/Akash4510/metaversus',
    img: metaversus,
  },
  {
    title: 'Regressors',
    desc: 'A community made for the programmers, to help them learn and get inspired from thousands of other great programers around the world.',
    link: '',
    githubLink: 'https://github.com/Akash4510/Regressors',
    img: regressors,
  },
];

export default projects;
